import React from "react";
import MainLayout from "src/layouts/Main";
import PageTitleHero from "components/PageTitleHero";

export default () => {
  return (
    <MainLayout>
      <PageTitleHero title="My <span>Journey</span>" class="about-hero" />
      <div className="flex-content-container">
        <div className="col-two"></div>
      </div>
    </MainLayout>
  );
};
